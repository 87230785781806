import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/root/personal-web-page/packages/gatsby-theme-googlesheets-personal-web/src/components/MdxLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contact / Address`}</h1>
    <h2><a parentName="h2" {...{
        "href": "mailto:mario.cagalj@fesb.hr"
      }}>{`mario.cagalj@fesb.hr`}</a></h2>
    <p><strong parentName="p">{`Prof. Mario Čagalj`}</strong><br parentName="p"></br>{`
`}{`University of Split, FESB`}<br parentName="p"></br>{`
`}{`R. Boskovica 32`}<br parentName="p"></br>{`
`}{`21000 Split`}<br parentName="p"></br>{`
`}{`Croatia`}</p>
    <p>
  <span style={{
        "color": "#aaa"
      }}>+385 (0)21 305 663</span>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      