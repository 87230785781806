import React from "react";
import Layout from "./Layout";
import Flex from "./Flex";

const MdxLayout = ({ children }) => (
  <Layout>
    <Flex direction="column" grow={1} halign="stretch">
      {children}
    </Flex>
  </Layout>
);

export default MdxLayout;
